<template>
  <div class="header">
    <div class="flex items-center justify-between header-content">
      <div class="left">
        <div class="logo">
          <img src="@/assets/imgs/KDPAY- LOGO 2.png" alt="" />
        </div>
        <div>Kendy Card</div>
      </div>
      <!-- PC端菜单 -->
      <div class="flex items-center justify-start right desktop-menu">
        <el-button type="info" class="customBtn loginBtn" @click="toHome">{{
          $t("首页")
        }}</el-button>
        <el-button type="info" class="customBtn loginBtn" @click="toActive">{{
          $t("激活指南")
        }}</el-button>
        <el-button type="info" class="customBtn loginBtn" @click="toLogin">{{
          $t("登录")
        }}</el-button>
        <el-button type="primary" class="customBtn" @click="toLogin">{{
          $t("注册")
        }}</el-button>
        <el-button type="primary" class="customBtn" @click="toLogin">{{
          $t("申请卡片")
        }}</el-button>
        <el-dropdown
          trigger="click"
          @command="handleCommand"
          placement="bottom"
        >
          <div class="langBox">
            <img src="@/assets/imgs/language.png" alt="" class="language" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(lang, index) in langs"
              :key="index"
              :command="lang"
              :class="curLanguage == lang.value ? 'languageActive' : ''"
              >{{ lang.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 移动端菜单按钮 -->
      <div class="mobile-menu-button" @click="drawerVisible = true">
        <div class="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <!-- 移动端抽屉菜单 -->
    <div
      v-if="drawerVisible"
      class="mobile-drawer-overlay"
      @click="drawerVisible = false"
    ></div>
    <div class="mobile-drawer" :class="{ 'drawer-open': drawerVisible }">
      <div class="drawer-content">
        <div class="drawer-header">
          <div class="close-button" @click="drawerVisible = false">
            <div class="close-icon"></div>
          </div>
        </div>
        <div class="drawer-body">
          <div class="menu-buttons">
            <el-button type="info" class="drawer-btn" @click="toHome">
              {{ $t("首页") }}
            </el-button>
            <el-button type="info" class="drawer-btn" @click="toActive">
              {{ $t("激活指南") }}
            </el-button>
            <el-button type="info" class="drawer-btn" @click="toLogin">
              {{ $t("登录") }}
            </el-button>
            <el-button type="primary" class="drawer-btn" @click="toLogin">
              {{ $t("注册") }}
            </el-button>
            <el-button type="primary" class="drawer-btn" @click="toLogin">
              {{ $t("申请卡片") }}
            </el-button>
          </div>
          <!-- <div class="drawer-language">
            <div class="language-list">
              <div
                v-for="(lang, index) in langs"
                :key="index"
                class="language-item"
                :class="{ 'language-active': curLanguage === lang.value }"
                @click="handleCommand(lang)"
              >
                {{ lang.text }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-page",
  data() {
    return {
      drawerVisible: false,
      langs: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
        { text: "日本语", value: "jp" },
      ],
      curLanguage: localStorage.getItem("accept_language"),
      currentLan: "",
    };
  },
  methods: {
    handleCommand(command) {
      localStorage.setItem("accept_language", command.value);
      this.$i18n.locale = command.value;
      this.currentLan = this.langs.find(
        (item) => item.value === command.value
      )?.text;
      window.location.reload();
    },
    toLogin() {
      window.open("https://card.kendycard.com/login");
    },
    toHome() {
      // 根据当前设备判断浏览器宽度
      if (window.innerWidth < 768) {
        window.location.href = "https://h5.kendycard.com/h5";
      } else {
        window.location.href = "https://kendycard.com";
      }
    },
    toActive() {
      window.open("https://kendycard.com/activation");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 10px;
}
.header {
  width: 100%;
  background-color: #000000;

  .header-content {
    max-width: 1440px;
    height: 82px;
    padding: 18px 60px;
    margin: 0 auto;
    color: #fff;
  }

  .left {
    display: flex;
    align-items: center;
    .logo {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    font-family: "MiSans-Bold";
    font-size: 26px;
    line-height: 35.67px;
    text-align: left;
  }

  .right {
    .loginBtn {
      color: #9b6d09;
    }
    .language {
      margin-left: 20px;
      cursor: pointer;
    }
    .active {
      background-color: transparent;
      background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "MiSans-Demibold";
      font-size: 14px;
      font-weight: 600;
      line-height: 18.56px;
      text-align: left;
    }
  }

  .mobile-menu-button {
    display: none;
    cursor: pointer;
    padding: 8px;

    .hamburger-icon {
      width: 32px;
      height: 26px;
      position: relative;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 11px;
        }
        &:nth-child(3) {
          top: 22px;
        }
      }
    }
  }
}

.mobile-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.mobile-drawer {
  position: fixed;
  top: 0;
  right: -85%;
  width: 35%;
  height: 100vh;
  background-color: #000000;
  z-index: 2001;
  transition: right 0.3s ease-in-out;

  &.drawer-open {
    right: 0;
  }

  .drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #000000;

    .drawer-header {
      padding: 16px;
      text-align: right;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .close-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;

        .close-icon {
          position: relative;
          width: 20px;
          height: 20px;

          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #fff;
            top: 50%;
            left: 50%;
            transform-origin: center;
          }

          &:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .drawer-body {
      flex: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 20px 16px;

      .menu-buttons {
        margin-bottom: 32px;

        .drawer-btn {
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 44px;
          margin-bottom: 12px;
          border-radius: 6px;

          &:last-child {
            margin-bottom: 0;
          }

          &.el-button--info {
            background: transparent;
            border: 1px solid #9b6d09;
            color: #9b6d09;
          }

          &.el-button--primary {
            background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
            border: none;
            color: #ffffff;
          }

          &.el-button {
            font-size: 14px !important;
          }

          span {
            font-size: 14px !important;
          }
        }
      }

      .drawer-language {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 20px;

        .language-list {
          .language-item {
            height: 48px;
            line-height: 48px;
            padding: 0 12px;
            color: #ffffff;
            font-size: 15px;
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            transition: background-color 0.2s ease;

            &:active {
              background-color: rgba(255, 255, 255, 0.05);
            }

            &.language-active {
              color: #846c37;
              font-weight: 500;
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }

  .mobile-drawer {
    .drawer-content {
      .drawer-body {
        .menu-buttons {
          .drawer-btn {
            font-size: 14px !important;

            &.el-button {
              font-size: 14px !important;
            }

            span {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .header {
    .header-content {
      padding: 18px 20px;
    }

    .desktop-menu {
      display: none;
    }

    .mobile-menu-button {
      display: block;
    }

    .left {
      font-size: 20px;

      .logo {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
