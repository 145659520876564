<template>
  <div>
    <Header />
    <div class="open-card-container">
      <div class="open-title">
        <span> 実物カードの有効化ガイド</span>
      </div>
      <div class="open-card-content">
        <div>
          <p class="open-card-title">
            <span>ステップ1:</span>
          </p>
          <p class="open-card-text">
            <span
              >アプリを開き、画面下のカードリストボタンをタップして、カードリストページに移動</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard1.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>ステップ2:</span>
          </p>
          <p class="open-card-text">
            <span
              >カードページで、アクティブにしたい実物カードを選択し、「アクティブ化」ボタンをタップして、アクティブ化ページに進む</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard2.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>ステップ3:</span>
          </p>
          <p class="open-card-text">
            <span
              >16桁のカード番号を入力し、「アクティベーションコードを取得」ボタンをタップします。コードはカード保持者のメールに送信されます。コードを入力後、ページ下部の「アクティブ化」ボタンをタップしてアクティブ化を完了します</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard3.png" alt="" />
          </div>
        </div>
        <div>
          <p class="open-card-title">
            <span>ステップ4:</span>
          </p>
          <p class="open-card-text">
            <span
              >カードが正常にアクティブ化されました。ポップアップの「OK」ボタンをタップしてカードリストページに戻り、アクティブ化が完了するまでお待ちください。</span
            >
          </p>
          <div class="open-card-content-img">
            <img src="@/assets/imgsnew/opencard4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <FooterSection /> -->
  </div>
</template>

<script>
import Header from "@/components/one/header.vue";
// import FooterSection from "@/components/layout/FooterSection.vue";
export default {
  components: {
    Header,
    // FooterSection,
  },
};
</script>
<!-- 媒体查询 -->
<style scoped lang="scss">
// 电脑端样式
.open-card-container {
  width: 400px;
  margin: 0 auto;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 30px;
}
.open-title {
  color: #000;
  font-family: MiSans;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
}
.open-card-content {
  .open-card-title {
    color: #ff0000;
    font-family: MiSans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .open-card-text {
    color: #000;
    font-family: MiSans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .open-card-content-img {
    margin-bottom: 20px;
  }
}

// 移动端样式
@media (max-width: 768px) {
  .open-title {
    //文字居中
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .open-card-container {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 10px 30px;
  }
  .open-card-container {
    .open-card-title {
      color: red;
      font-family: MiSans;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0%;
      margin-bottom: 2px;
    }
    .open-card-text {
      color: #000;
      font-family: MiSans;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0%;
      margin-bottom: 10px;
    }
    .open-card-content-img {
      margin-bottom: 20px;
    }
  }
}
</style>
